import React from "react";

import Navbar from "../components/navbar";
import PageHeader from "../components/page-header";
import SEO from "../components/seo";
import Footer from "../components/footer";
import ContactModal from "./contact-modal";
import HiddenFormForNetlify from "./hidden-form-for-netlify";

class InternalPageLayout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayContactModal: false,
      displayThanks: typeof window !== 'undefined' && new URLSearchParams(window.location.search).get("form-success")
    }
  }

  displayModal() {
    this.setState({ displayContactModal: true })
  }

  hideModal() {
    this.setState({ displayContactModal: false })
  }

  render() {
    const modal = (this.state.displayContactModal || this.state.displayThanks) ? <ContactModal hideModal={this.hideModal.bind(this)} displayThanks={this.state.displayThanks} /> : <HiddenFormForNetlify />;
    
    return (
      <div>
        <SEO
          title="Residential electrian in Baltimore County"
          keywords={[`baltimore residential electrician`, `baltimore commercial electrician`]}
        />
        <Navbar displayModal={this.displayModal.bind(this)} />
        <div className="w-11/12 max-w-6xl mx-auto">
          <PageHeader title={this.props.title} crumbs={this.props.crumbs} />
          {this.props.children}
        </div>
        <Footer />
        {modal}
      </div>
    )
  }
}

export default InternalPageLayout;