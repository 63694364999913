import React from "react";
import SEO from "../components/seo";

import InternalPageLayout from "../components/internal-page-layout";
import InternalPageContentCard from "../components/internal-page-content-card";

import esgrPhoto from "../images/esgr-photo.jpg"

function IndexPage() {
  return (
    <div>
      <InternalPageLayout title="About Wyatt Electric" crumbs={["Home"]}>
        <SEO
          title="Electrical contractor in Baltimore County" />
        <InternalPageContentCard>
          <div className="w-full md:w-2/5 lg:w-1/2 mb-6 md:ml-6 md:float-right">
            <img src={esgrPhoto} className="max-w-full" alt="smart home installation services" />
          </div>

          <p className="text-lg mb-6 font-bold">Founded in 1986, Wyatt Electric Inc. proudly provides the highest quality electrical services in the Baltimore Area. Our small company is comprised of 8 employees, 4 of which are Master Electricians.</p>
          <p className="mb-6">The attention to detail and professionalism of all the employees is unsurpassed and the customizing of any project makes sure the customer receives exacting what is envisioned. Wyatt Electric Inc. is committed to maintaining knowledge on current effective, reliable and energy-efficient products on the market. Having former members of the Marine Corps, we have great appreciation for all military and first responders past and present.</p>
          <p>In 2010 Wyatt Electric Inc. was awarded the Employer Support of the Guard and Reserve’s “Above and Beyond” Award for exceeding expectations of an employer of Guard and Reserve members.</p>
        </InternalPageContentCard>
      </InternalPageLayout>
    </div>
  );
}

export default IndexPage;
