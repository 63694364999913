import React from "react";
import heroImg from "../images/hero-background.jpg";

const PageHeader = (props) => {
  return (
    <div className="mt-12 mb-6">
      <div className="max-w-full">
        <span className="font-lg">
          <span className="font-thin text-gray-600">{props.crumbs.join("&#187;")}<span className="mx-2">&#187;</span></span>
          <h1 className="page-header inline">{props.title}</h1>
        </span>
      </div>
    </div>
  )
}

export default PageHeader;