import React from "react";

const InternalPageContentCard = ({children}) => (
  <div className="bg-white rounded w-full mb-12">
    <div className="p-4 md:p-6 lg:p-12 text-gray-800">
      {children}
    </div>
  </div>
)

export default InternalPageContentCard;